var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "product-view", attrs: { flat: "" } },
    [
      _c(
        "v-card-title",
        { staticClass: "pa-1 pt-0" },
        [
          _c("barcode-scanner", {
            attrs: { search: _vm.scanCode, scannerPayload: _vm.scannerPayload },
            model: {
              value: _vm.scanCode,
              callback: function ($$v) {
                _vm.scanCode = $$v
              },
              expression: "scanCode",
            },
          }),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "px-0" },
        [
          _vm.loading
            ? _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c("v-progress-circular", {
                    attrs: {
                      size: 70,
                      width: 7,
                      color: "primary",
                      indeterminate: "",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.productMeta
            ? [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "px-1 py-0 font-weight-bold",
                        attrs: { cols: _vm.windowSize < 360 ? "12" : "4" },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("message.product.barcode")) +
                            ": " +
                            _vm._s(_vm.productMeta.productbarcode)
                        ),
                      ]
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "py-0 px-1 font-weight-bold",
                        class: _vm.windowSize < 360 ? "" : "text-end",
                        attrs: { cols: _vm.windowSize < 360 ? "12" : "8" },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("message.product.supplierartno")) +
                            ": " +
                            _vm._s(_vm.productMeta.supplierartnumber)
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "pa-1 pt-0 pb-0",
                        attrs: { cols: _vm.windowSize < 360 ? "12" : "6" },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("message.order.products")) +
                            ": " +
                            _vm._s(_vm.productMeta.productname)
                        ),
                      ]
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "pa-1 pt-0 pb-0",
                        class: _vm.windowSize < 360 ? "" : "text-end",
                        attrs: { cols: _vm.windowSize < 360 ? "12" : "6" },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("message.product.supplier")) +
                            ": " +
                            _vm._s(_vm.productMeta.suppliername)
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  { staticClass: "mb-1" },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "pa-1 highlight font-weight-bold",
                        attrs: { cols: "6" },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("message.product.minQty")) +
                            ": " +
                            _vm._s(
                              _vm._f("replaceDotWithComma")(
                                _vm.productMeta.productminqty
                              )
                            )
                        ),
                      ]
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "pa-1 highlight font-weight-bold",
                        attrs: { cols: "6" },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("message.product.orderQty")) + ": 0"
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._l(_vm.listOfLocations, function (location) {
                  return _c(
                    "v-row",
                    { key: location.locationid, staticClass: "highlight" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-1", attrs: { cols: "6" } },
                        [_vm._v(" " + _vm._s(location.locationname) + " ")]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pa-1", attrs: { cols: "6" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("message.product.qty")) +
                              ": " +
                              _vm._s(
                                _vm._f("replaceDotWithComma")(location.onstock)
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                _c(
                  "v-row",
                  [
                    _vm._l(_vm.listOfCustomerOrders, function (order, index) {
                      return _c(
                        "v-col",
                        {
                          key: index + "_customer",
                          staticClass: "pa-0",
                          attrs: { cols: "12" },
                        },
                        [
                          _c("customer-item", {
                            attrs: { isList: false, model: order },
                          }),
                        ],
                        1
                      )
                    }),
                    _vm._l(_vm.listOfSupplierOrders, function (order, index) {
                      return _c(
                        "v-col",
                        {
                          key: index + "_supplier",
                          staticClass: "pa-0 pt-0",
                          attrs: { cols: "12" },
                        },
                        [
                          _c("supplier-item", {
                            attrs: {
                              type: "supplier",
                              isList: false,
                              model: order,
                            },
                          }),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
              ]
            : [
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    !_vm.loading
                      ? _c("v-img", {
                          staticClass: "mx-auto",
                          attrs: {
                            width: "40%",
                            height: "100%",
                            src: require("@/assets/noDataAvailable.jpg"),
                          },
                        })
                      : _vm._e(),
                    !_vm.loading
                      ? _c("h3", [
                          _vm._v(
                            _vm._s(_vm.$t("message.product.noProductFound"))
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }